<template>
  <ol class="accordion">
    <slot />
  </ol>
</template>

<script lang="ts" setup>
const accordion = reactive({
  count: 0,
  active: null,
})
provide('accordionElement', accordion)
</script>

<style lang="scss" scoped>
.accordion {
  list-style: none;
  margin: 0;
  padding: 0;
  min-height: 80px;

  &__item:last-child {
    border-bottom: none;
  }
}
</style>
